
import { StepperComponent } from "@/assets/ts/components";
import Step1 from "@/components/wizard/profesional/Step1.vue";
import Step2 from "@/components/wizard/profesional/Step2.vue";
import Step3 from "@/components/wizard/profesional/Step3.vue";
import Step4 from "@/components/wizard/profesional/Step4.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { Experiencia, IAMFile, Idioma, Tiempo } from "@/types";
import { isDate, parse } from "date-fns";
import { ElMessage } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { mapGetters, useStore } from "vuex";
import * as Yup from "yup";

interface IStep1 {
  tratamiento: string;
  nombre: string;
  apellidos: string;
  dni: string;
  email: string;
  telefono: string;
  fechaNacimiento: Date | null;
  direccion: string;
  comarca: string;
  provincia: string;
  localidad: string;
  carnetConducir: boolean | null;
  vehiculoPropio: string;
  idiomas: Idioma[];
}

interface IStep2 {
  comarcasTrabajo: string[];
  disponeTitulacion: boolean | null;
  titulacion: [];
  titulacionOtras: string;
  experienciaCuidadoMenores: string;
  titulacionAdjunta: IAMFile[] | null;
  certificadoAntecedentes: IAMFile[] | null;
  experiencia: Experiencia[];
}

interface IStep3 {
  disponibilidad: Tiempo[];
  laborables: boolean;
  finesDeSemana: boolean;
  festivos: boolean;
  urgencias: boolean;
  noches: boolean;
  detalleDisponibilidad: string;
}

interface IStep4 {
  politicaPrivacidad: boolean;
  declaracionResponsable: boolean;
}

interface CreateAccount extends IStep1, IStep2, IStep3, IStep4 {}

export default defineComponent({
  name: "kt-horizontal-wizard",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },

  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const formData = ref<CreateAccount>({
      tratamiento: "",
      nombre: "",
      apellidos: "",
      dni: "",
      email: "",
      telefono: "",
      fechaNacimiento: null,
      direccion: "",
      comarca: "",
      provincia: "",
      localidad: "",
      carnetConducir: null,
      vehiculoPropio: "",
      idiomas: [{ nombreIdioma: [], nivel: [] }],
      comarcasTrabajo: [],
      disponeTitulacion: null,
      titulacion: [],
      titulacionOtras: "",
      experienciaCuidadoMenores: "",
      experiencia: [{ tipo: "", dirigidoA: "", duracion: "" }],
      titulacionAdjunta: [],
      certificadoAntecedentes: [],
      disponibilidad: [{ dias: [], horas: [] }],
      laborables: false,
      finesDeSemana: false,
      festivos: false,
      urgencias: false,
      noches: false,
      detalleDisponibilidad: "",
      politicaPrivacidad: false,
      declaracionResponsable: false,
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );

      setCurrentPageBreadcrumbs("Horizontal", ["Pages", "Wizards"]);
    });

    const i18n = (text) => (te(text) ? t(text) : text);

    const createAccountSchema = [
      // TODO: i18n para los labels? revisar https://github.com/jquense/yup#using-a-custom-locale-dictionary
      Yup.object({
        tratamiento: Yup.string().required().label(i18n("TratamientoLabel")),
        nombre: Yup.string().required().label(i18n("profesionalNombreLabel")),
        apellidos: Yup.string()
          .required()
          .label(i18n("profesionalApellidosLabel")),
        dni: Yup.string().required().label(i18n("profesionalDNILabel")),
        email: Yup.string()
          .required()
          .email()
          .matches(/^(")?(?:[^."])(?:(?:[.])?(?:[\w\-!#$%&'*+/=?^_`{|}~]))*\1@(\w[-\w]*\.){1,5}([A-Za-z]){2,9}$/,
            i18n("profesionalEmailError"))

          .label(i18n("profesionalEmailLabel")),
        telefono: Yup.string()
          .required()
          .label(i18n("profesionalTelefonoLabel")),
        fechaNacimiento: Yup.date()

          .typeError(i18n("errorFaltaFecha"))
          .transform((v, o) =>
            !isDate(o) ? parse(o, "dd/MM/yyyy", new Date()) : o
          )
          .max(new Date())
          .required(),
        direccion: Yup.string()
          .required()
          .label(i18n("DireccionDireccionLabel")),
        comarca: Yup.string().required().label(i18n("DireccionComarcaLabel")),
        provincia: Yup.string()
          .required()
          .label(i18n("DireccionProvinciaLabel")),
        localidad: Yup.string()
          .required()
          .label(i18n("DireccionLocalidadLabel")),
        carnetConducir: Yup.boolean()
          .typeError(i18n("errorFaltaOpciones"))
          .required(),
        vehiculoPropio: Yup.string()
          .typeError(i18n("errorFaltaOpciones"))
          .when("carnetConducir", {
            is: true,
            then: Yup.string()
              .typeError(i18n("errorFormato"))
              .required()
              .label(i18n("VehiculoPropioValidacionLabel")),
            otherwise: Yup.string().typeError(i18n("errorFormato")).nullable(),
          }),
        idiomas: Yup.array()
          .of(
            Yup.object({
              nombreIdioma: Yup.array().ensure().min(1),
              nivel: Yup.array().ensure().min(1),
            })
          )
          .ensure()
          .min(1)
          .test(
            "elemento-completo",
            i18n("errorFaltaIdiomaNivel"),
            (value) =>
              !value?.some(
                (ele) => !ele?.nombreIdioma?.length || !ele?.nivel?.length
              )
          ),
      }),
      Yup.object({
        comarcasTrabajo: Yup.array()
          .of(Yup.string())
          .ensure()
          .min(1, i18n("OpcionInicialComarca")),
        disponeTitulacion: Yup.boolean()
          .typeError(i18n("errorFaltaOpciones"))
          .required(),
        titulacion: Yup.array()
          .of(Yup.string())
          .required()
          .min(1, i18n("errorFaltaTitulacion")),
        titulacionOtras: Yup.string().when("titulacion", {
          is: (titulacion) => titulacion.includes("OTRAS"),
          then: Yup.string().required().label(i18n("titulacionesOtrasLabel")),
          otherwise: Yup.string().nullable(),
        }),
        experienciaCuidadoMenores: Yup.string()
          .typeError(i18n("errorFaltaOpciones"))
          .required()
          .label(i18n("ExperienciaCuidadoMenoresValidacionLabel")),
        experiencia: Yup.array().when("experienciaCuidadoMenores", {
          is: (experienciaCuidadoMenores) =>
            experienciaCuidadoMenores !== "SINEXPERIENCIA",
          then: Yup.array()
            .of(
              Yup.object({
                tipo: Yup.string().required(),
                dirigidoA: Yup.string().required(),
                duracion: Yup.string().required(),
              })
            )
            .ensure()
            .min(1)
            .test(
              "elemento-completo",
              i18n("errorFaltanCampos"),
              (value) =>
                !value?.some(
                  (ele) => !ele?.tipo || !ele?.dirigidoA || !ele?.duracion
                )
            ),
          otherwise: Yup.array(),
        }),
        titulacionAdjunta: Yup.array().when("disponeTitulacion", {
          is: true,
          then: Yup.array().min(1, i18n("errorFaltaArchivo")),
          otherwise: Yup.array(),
        }),
        certificadoAntecedentes: Yup.array().min(1, i18n("errorFaltaArchivo")),
      }),
      Yup.object({
        disponibilidad: Yup.array()
          .of(
            Yup.object({
              dias: Yup.array().ensure().min(1),
              horas: Yup.array().ensure().min(1),
            })
          )
          .ensure()
          .min(1)
          .test(
            "elemento-completo",
            i18n("errorFaltaDiaHora"),
            (value) =>
              !value?.some((ele) => !ele?.dias?.length || !ele?.horas?.length)
          ),
        laborables: Yup.boolean(),
        finesDeSemana: Yup.boolean(),
        festivos: Yup.boolean(),
        urgencias: Yup.boolean(),
        noches: Yup.boolean(),
        detalleDisponibilidad: Yup.string(),
      }),
      Yup.object({
        politicaPrivacidad: Yup.boolean().isTrue(
          i18n("politicaPrivacidadValidacion")
        ),
        declaracionResponsable: Yup.boolean().isTrue(
          i18n("declaracionResponsableValidacion")
        ),
      }),
    ];

    const currentSchema = computed(() => {
      return createAccountSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm<
      IStep1 | IStep2 | IStep3 | IStep4
    >({
      validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      if (
        totalSteps.value != null &&
        currentStepIndex.value + 1 >= totalSteps.value
      ) {
        return formSubmit(formData.value);
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const formSubmit = (formData) => {
      store
        .dispatch(Actions.POST_PROFESIONALES, {
          ...formData,
          fechaNacimiento: parse(
            formData.fechaNacimiento,
            "dd/MM/yyyy",
            new Date()
          ),
        })
        .then((codigo) => {
          Swal.fire({
            html: t("formularioEnviadoBody", { codigo }),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: i18n("cerrarBtn"),
            customClass: {
              confirmButton: "btn btn-lg btn-primary",
            },
          }).then(() => {
            window.location.href = "/#/home";
          });
        })
        .catch((err) => {
          console.error("Err", err);
          ElMessage.error(t("formularioEnviadoError"));
        });
    };

    return {
      horizontalWizardRef,
      previousStep,
      handleStep,
      totalSteps,
      currentStepIndex,
    };
  },
  computed: {
    ...mapGetters(["isPendingForm"]),
  },
});
